<template>
  <viewcard--c
    :title="
      $route.params.id > 0
        ? 'Atualizar Grupo Estudio'
        : 'Cadastrar Grupo Estudio'
    "
    :btsave="$route.params.id > 0 ? btedit : btcreate"
    :btdelete="$route.params.id > 0 ? btdelete : null"
    :btback="{}"
    :busy="loading"
    @clicked-save="save"
    @clicked-delete="confirmDelete"
  >
    <b-modal
      id="modal-semana"
      ref="modal-semana"
      cancel-variant="outline-secondary"
      ok-title="Confirmar"
      cancel-title="Fechar"
      centered
      title="Dia da Semana"
      @ok="onClickSaveWeekAvailable"
      @hide="onHideWeek"
    >
      <b-form>
        <b-form-group label="Semana">
          <v-select
            v-model="weekModel.weekSelected"
            :options="weekList"
            autocomplete="off"
            :clearable="false"
          />
        </b-form-group>
        <b-form-group label="Limite">
          <b-form-input
            placeholder="Limite por semana"
            v-model="weekModel.limit"
            type="number"
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <b-form @submit.prevent>
      <b-tabs pills>
        <b-tab>
          <template #title>
            <span class="d-none d-sm-inline">Dados Básicos</span>
          </template>
          <b-row>
            <b-col md="6">
              <b-form-group label="Nome" label-for="name">
                <b-form-input
                  v-model="record.name"
                  placeholder="Nome do Estúdio"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="E-mail">
                <div class="form-label-group">
                  <b-form-input
                    type="email"
                    v-model="record.email"
                    placeholder="Informe um e-mail"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Quantidade">
                <div class="form-label-group">
                  <b-form-input
                    v-model="record.quantity"
                    placeholder="Quantidade"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Localidade">
                <v-select
                  v-if="places.length > 0 && placeSelected"
                  v-model="placeSelected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="places"
                  autocomplete="off"
                  :clearable="false"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Escala">
                <v-select
                  v-if="scales.length > 0"
                  v-model="scaleSelected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="scales"
                  autocomplete="off"
                  :clearable="false"
                  multiple
                />
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label="Estúdio de Terceiros?">
                <b-form-checkbox
                  class="custom-control-success mt-1"
                  name="check-button"
                  switch
                  v-model="record.is_third"
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>

        <!-- AVAILABLE WEEK -->
        <b-tab>
          <template #title>
            <span class="d-none d-sm-inline">Disponibilidade Semanal</span>
          </template>
          <b-button
            size="sm"
            variant="outline-primary"
            class="btn-tour-skip mr-1 mb-1"
            @click="onClickNew"
          >
            <feather-icon icon="PlusIcon" size="22" />
          </b-button>

          <b-row
            v-if="record.availableWeeks && record.availableWeeks.length > 0"
          >
            <b-table
              :items="!loading ? record.availableWeeks : []"
              :fields="fields"
              :busy="loading"
              responsive
              striped
              hover
            >
              <template #cell(actions)="data">
                <div class="text-nowrap">
                  <feather-icon
                    @click="onClickWeek(data.item)"
                    icon="EditIcon"
                    class="mx-1"
                    size="22"
                  />
                  <feather-icon
                    @click="removeAvailableWeek(data.item.week)"
                    icon="TrashIcon"
                    class="mx-1"
                    size="22"
                  />
                </div>
              </template>
            </b-table>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-form>
  </viewcard--c>
</template>
<script>
import _studioGroupService from "@/services/studios-group-service";
import _placesService from "@/services/places-service";
import _scalesService from "@/services/scales-service";
export default {
  data() {
    return {
      btedit: { permission: `permission.studiogroup.edit` },
      btcreate: { permission: `permission.studiogroup.create` },
      btdelete: { permission: `permission.studiogroup.delete` },
      loading: false,
      places: [],
      placeSelected: { label: "localidade....", value: "-3" },
      scaleSelected: null,
      scales: [],
      record: {
        id: 0,
        name: "",
        place: "",
        quantity: 0,
        capacity: 0,
        email: "",
        availableWeeks: [],
      },
      fields: [
        { key: "weekds", label: "Semana" },
        { key: "limit", label: "Limite" },
        { key: "actions", label: "Ações" },
      ],
      weekModel: {
        weekSelected: null,
        limit: 0,
        id: 0,
      },
      weekList: [
        { label: "Domingo", value: 0 },
        { label: "Segunda Feira", value: 1 },
        { label: "Terça Feira", value: 2 },
        { label: "Quarta Feira", value: 3 },
        { label: "Quinta Feira", value: 4 },
        { label: "Sexta Feira", value: 5 },
        { label: "Sábado", value: 6 },
      ],
    };
  },
  created() {
    this.getplaces();
    this.getscales();
  },
  mounted() {
    this.getRecord();
  },
  methods: {
    onClickWeek(_item) {
      this.weekModel.limit = _item.limit;
      this.weekModel.weekSelected = this.weekList.filter(
        (f) => f.value.toString() === _item.week.toString()
      )[0];
      this.weekModel.id = _item.id;
      this.$refs["modal-semana"].show();
    },
    onClickNew() {
      this.weekModel.weekSelected = null;
      this.weekModel.limit = 0;
      this.weekModel.id = 0;

      this.$refs["modal-semana"].show();
    },
    addAvailableWeek(week, limit) {
      let payload = {
        id: this.weekModel.id,
        weekds: this.weekList.filter(
          (f) => f.value.toString() === week.toString()
        )[0].label,
        limit: limit,
        week: week,
      };

      if (this.weekModel.id > 0) {
        this.record.availableWeeks.filter(
          (f) => f.id === this.weekModel.id
        )[0].week = payload.week;

        this.record.availableWeeks.filter(
          (f) => f.id === this.weekModel.id
        )[0].weekds = payload.weekds;

        this.record.availableWeeks.filter(
          (f) => f.id === this.weekModel.id
        )[0].limit = payload.limit;

        //= payload;
      } else {
        if (
          !this.record.availableWeeks.some(
            (s) => s.week.toString() === week.toString()
          )
        ) {
          this.record.availableWeeks.push(payload);
        }
      }
    },
    removeAvailableWeek(week) {
      this.record.availableWeeks = this.record.availableWeeks.filter(
        (f) => f.week.toString() !== week.toString()
      );
    },
    onClickSaveWeekAvailable() {
      this.addAvailableWeek(
        this.weekModel.weekSelected.value,
        this.weekModel.limit
      );
    },
    onHideWeek(evt) {
      if (evt.trigger === "backdrop") {
        evt.preventDefault();
      }
    },
    getplaces() {
      this.loading = true;
      _placesService
        .show()
        .then((res) => {
          this.places = this.$utils.populardropdown(
            res.content,
            "name",
            "id",
            false,
            false
          );
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
    getscales() {
      this.loading = true;
      _scalesService
        .show()
        .then((res) => {
          this.scales = this.$utils.populardropdown(
            res.content,
            "name",
            "id",
            false,
            false
          );
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
    getRecord() {
      if (this.$route.params.id > 0) {
        this.loading = true;
        _studioGroupService
          .find(this.$route.params.id)
          .then((res) => {
            if (
              res.content.availableWeeks &&
              res.content.availableWeeks.length > 0
            ) {
              res.content.availableWeeks.forEach((_wwe) => {
                _wwe.weekds = this.weekList.filter(
                  (f) => f.value.toString() === _wwe.week.toString()
                )[0].label;
              });
            }
            this.record = res.content;

            if (this.record.place) {
              this.placeSelected = {
                label: this.record.place.name,
                value: this.record.place.id,
              };
            }

            if (this.record.studiosGroupScales) {
              this.scaleSelected = this.record.studiosGroupScales.map((m) => {
                return {
                  label: m.scale.name,
                  value: m.scale.id,
                };
              });
              this.record.studiosGroupScales = null;
            }
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.loading = false));
      }
    },
    save() {
      if (this.placeSelected) {
        this.record.place_id = this.$utils.getValueSelected(this.placeSelected);
        this.record.place = { name: this.placeSelected.label };
      }

      if (this.scaleSelected) {
        this.record.studiosgroupscales = [];
        this.scaleSelected.forEach((_scale) => {
          this.record.studiosgroupscales.push({
            scale_id: _scale.value,
          });
        });
      }

      const payload = { data: { ...this.record } };

      //promisse
      const _createOrUpdate =
        this.record.id <= 0
          ? _studioGroupService.create(payload)
          : _studioGroupService.update(payload);

      this.loading = true;
      _createOrUpdate
        .then(() => {
          this.$utils.toast("Notificação", "Registro salvo com sucesso.");
          this.$router.go(-1);
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
    confirmDelete() {
      this.$swal({
        title: "Tem certeza?",
        text: "Isso não pode ser revertido!",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero excluir!",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-info",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.excluir();
        }
      });
    },
    excluir() {
      this.loading = true;
      _studioGroupService
        .delete(this.record.id)
        .then(() => {
          this.$utils.toast("Notificação", "Excluido com sucesso.");
          this.$router.go(-1);
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
  },
};
</script>